import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import {
    Alert,
    Accordion,
} from "react-bootstrap";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { MedicalOpinionData } from "@pages/subPages/MedicalOpinionData/MedicalOpinionData";
import { MedicalDataAdditional } from "@pages/subPages/AdditionalMedicalData/MedicalDataAdditional";
import { AuthLetters } from "@pages/subPages/AuthLetter/AuthLetters";
import { getBySlug } from "@services/hospitalizationcrud";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { MedicAssigned } from "@pages/subPages/MedicAssigned/MedicAssigned";
import { MedicAssignationTrakingMedic } from "@pages/subPages/MedicAssignationTracking/MedicAssignationTrackingMedic";
import { IfHasRole } from "@metronic/_helpers";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import CaseContext from "@contexts/CaseContext";
import BlockCaseContext from "@contexts/BlockCaseContext";
import {isCaseInCorrectModule} from "../../../_utils/checkModuleStatus";
import {STATUS} from "../../../helpers/status";
import {Redirect} from "react-router-dom";

const CasesCanceledDetails = (props) => {
    const subheader = useSubheader();

    const { slug } = props.match.params;

    const [caseRecord, setCaseRecord] = useState({});
    const [letterMov, setLetterMov] = useState({});
    const [totalLetters, setTotalLetters] = useState("0");
    const [, setIsFinalReject] = useState(false);
    const [shouldBlockCase, setShouldBlockCase] = useState(true);

    useEffect(() => {
        window.wasCommented = false;
        getBySlug(slug).then((response) => {
            setCaseRecord(response.data);
            setIsFinalReject(response.data.statusDictum === "Rechazo Definitivo")
            getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
                setLetterMov(currrentLetterMov.data)
            );
        });

        subheader.setTitle("Cancelaciones");
    }, [slug]);

    if (!isCaseInCorrectModule(caseRecord, [STATUS.CANCELED, STATUS.TRACING, STATUS.MEDIC_INFORMATION, STATUS.MEDICAL_OPINION, STATUS.COST_CONTROL, STATUS.RECONSIDERATION])) {
        return <Redirect to="/error/unauthorized"/>;
    }

    return (
        <>
            <Alert
                variant="success"
                className="text-center"
                style={{ fontSize: "16px" }}
            >
                Cancelaciones
            </Alert>

            <CaseHeader caseRecord={caseRecord} />

            <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
                <Accordion className="mt-5">
                    <InssuredData
                        icon="fa-search"
                        eventKey="1"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={() => {}}
                    />
                    <HospitalData
                        icon="fa-search"
                        eventKey="2"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={() => {}}
                    />
                    <MedicAssigned
                        icon="fa-search"
                        eventKey="7"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={() => {}}
                    />
                    <Documentation
                        icon= "fa-search"
                        icone="fa-pencil-alt"
                        eventKey="4"
                        data={caseRecord}
                        disabledAll={true}
                        {...props}
                    />
                    <MedicalFees
                        icon= "fa-search"
                        icone="fa-pencil-alt"
                        eventKey="5"
                        data={caseRecord}
                        hideBtnInsurance={false}
                        hideFormCreate={true}
                        disabledAll={true}
                        showForm={false}
                        {...props}
                    />
                    <AuthLetters
                        icon="fa-pencil-alt"
                        showForm={false}
                        eventKey="3"
                        data={caseRecord}
                        disabledAll={true}
                        setTotalLetters={setTotalLetters}
                        {...props}
                    />
                </Accordion>
                <Accordion className="mt-5">
                    { (IfHasRole("Admin") || IfHasRole("ADMINAXA") ) && <MedicAssignationTrakingMedic
                        icon="fa-pencil-alt"
                        eventKey="0"
                        data={caseRecord}
                        mcMovement={letterMov}
                        disabledAll={true}
                        bindSubmitForm={() => {}}
                        bindErrors={() => {}}
                    /> }
                    <MedicalOpinionData
                        icon="fa-pencil-alt"
                        setIsFinalReject={setIsFinalReject}
                        eventKey="1"
                        data={caseRecord}
                        letterMov={letterMov}
                        totalLetters={totalLetters}
                        disabledAll={true}
                        bindSubmitForm={() => {}}
                        bindErrors={() => {}}
                    />
                    <MedicalDataAdditional
                        icon="fa-pencil-alt"
                        eventKey="6"
                        data={caseRecord}
                        disabled={true}
                        bindSubmitForm={() => {}}
                    />
                </Accordion>
                <Accordion defaultActiveKey="5" className="mt-5">
                    <CaseContext.Provider value={[caseRecord, setCaseRecord]}>
                        <Binnacle
                            icon="fa-pencil-alt"
                            eventKey="5"
                            data={caseRecord}
                            disabledAll={false}
                            hideButton={true}
                            hideReason={false}
                            eventStage="Cancelaciones"
                            {...props}
                        />
                    </CaseContext.Provider>
                </Accordion>
            </BlockCaseContext.Provider>
        </>
    );
};

export default CasesCanceledDetails;
