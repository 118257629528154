
import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import {
  Alert,
  Col,
  Row,
  Card,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import {Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { MedicalOpinionData } from "@pages/subPages/MedicalOpinionData/MedicalOpinionData";
import { MedicalDataAdditional } from "@pages/subPages/AdditionalMedicalData/MedicalDataAdditional";
import { AuthLetters } from "@pages/subPages/AuthLetter/AuthLetters";
import { ProvidersData } from "@pages/subPages/SurgeryProvidersData/ProvidersData";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import {
  getLastLetterMovByCase,
  getLetterMovsByCase,
} from "@services/letterMovsService";
import { MedicAssigned } from "@pages/subPages/MedicAssigned/MedicAssigned";
import moment from "moment";
import { isEmpty } from "@utils/checkObjectEmpty";
import { MedicAssignationTrakingMedic } from "@pages/subPages/MedicAssignationTracking/MedicAssignationTrackingMedic";
import { IfHasRole } from "@metronic/_helpers";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {isCaseInCorrectModule} from "../../../_utils/checkModuleStatus";
import {STATUS} from "../../../helpers/status";

const MedicalOpinionDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [letterMov, setLetterMov] = useState({});
  const [letterMovs, setLetterMovs] = useState([]);
  const [totalLetters, setTotalLetters] = useState("0");
  const [isFinalReject, setIsFinalReject] = useState(false);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      setIsFinalReject(response.data.statusDictum === "Rechazo Definitivo")
      getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
        setLetterMov(currrentLetterMov.data)
      );
    });

    subheader.setTitle("DICTAMEN MÉDICO");
  }, [slug]);

  let history = useHistory();
  let submitMedicalOpinionData = null;
  let submitMedicalDataAdditional = null;
  let submitMedicalOpinionDataErrors = null;

  const query = new URLSearchParams(props.location.search);

  const bindSubmitFormMedicalOpinionData = (submitForm) =>
    (submitMedicalOpinionData = submitForm);

  const bindSubmitFormMedicalDataAdditional = (submitForm) =>
    (submitMedicalDataAdditional = submitForm);

    const bindSubmitMedicalOpinionErrors = (errors) =>
    (submitMedicalOpinionDataErrors = errors);

  let submitFormMedicAssignation = null;
  let submitFormMedicAssignationErrors = null;

  const bindSubmitFormMedicAssignation = (submitForm) =>
    (submitFormMedicAssignation = submitForm);

  const bindSubmitFormMedicAssignationErrors = (errors) =>
    (submitFormMedicAssignationErrors = errors);

  const onClickSendControlDesk = async () => {
    await submitMedicalOpinionData();
    if (isEmpty(submitMedicalOpinionDataErrors)) {
      let inputReasonControlDesk = document.getElementById(
        "reason_sendToControlDesk"
      ).value;
      if (inputReasonControlDesk === "") {
        alert("Debe seleccionar una razon");
        return;
      }
      await submitMedicalOpinionData();
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get('/cases/getTimestamp');
      update(
        { caseStatusId: 2, reasonMC: inputReasonControlDesk, feamesaControl: moment.utc(timestamp.data).format() },
        caseRecord.id
      ).then((response) => {
        history.push("/dictamen_medico");
      });
    }
  };

  const onClickCostControl = async () => {
    if (caseRecord.caseType === "Alta hospitalaria" && document.getElementById("si").value === "si" && !caseRecord.nurseFollowId) {
      alert("El caso no cuenta con Enfermer@ Asignad@ para el seguimiento en el siguiente modulo, favor de reportarlo a mesa de control");
      return;
    }

    await submitMedicalOpinionData();
    if (isEmpty(submitMedicalOpinionDataErrors)) {
      var someLetterAuthIsFinal;
      await submitMedicalDataAdditional();
      await getLetterMovsByCase(caseRecord?.id).then( result => {
        setLetterMovs(result.data);
        someLetterAuthIsFinal = result.data.some(letterMov => letterMov.letterType === "Final");
      });

      if (caseRecord.caseType !== "Reconsideracion de dictamen" && caseRecord.caseType !== "Alta express" && caseRecord.caseType !== "Revision de corte" && !isFinalReject) {
        if ( (caseRecord.caseType !== "Alta hospitalario" && caseRecord.caseType !== "Alta hospitalaria"
            && caseRecord.caseType !== "Reconsideración de Alta") || !document.getElementById('si').checked) {
          alert("No se puede enviar el caso a este Modulo porque no es Alta Hospitalaria y la Carta no es Suficiente.");
          return;
        }
      }

      await submitMedicalOpinionData();
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get('/cases/getTimestamp');
      update(
        { caseStatusId: 6, feaccostos: moment.utc(timestamp.data).format() }, 
        caseRecord.id
        ).then((response) => {
        history.push("/dictamen_medico");
      });
    }
  }

  const onClickSendMedicInformation = async () => {
    await submitMedicalOpinionData();
    if (isEmpty(submitMedicalOpinionDataErrors)) {
      let inputReasonMedicInformation = document.getElementById(
        "reason_sendToMedicInformation"
      ).value;
      if (inputReasonMedicInformation === "") {
        alert("Debe seleccionar una razon");
        return;
      }

      if (inputReasonMedicInformation === "Informacion medica adicional") {
        let checkboxesMedicalDataAdditional = Array.prototype.slice.call(
          document.getElementsByName("additionalMedicInformation")
        );
        let someValueIsChecked = checkboxesMedicalDataAdditional.some(
          (checkbox) => checkbox.checked
        );
        if (!someValueIsChecked) {
          alert("Debes seleccionar un checkbox de Informacion Medica Adicional");
          return;
        }
      }

      await submitMedicalDataAdditional();
      await submitMedicalOpinionData();
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get('/cases/getTimestamp');
      update(
        { caseStatusId: 4, informationReason: inputReasonMedicInformation, feaimedica: moment.utc(timestamp.data).format() },
        caseRecord.id
      ).then((response) => {
        history.push("/dictamen_medico");
      });
    }
  };

  if (!isCaseInCorrectModule(caseRecord, [STATUS.MEDICAL_OPINION])) {
    return <Redirect to="/error/unauthorized"/>;
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        DICTAMEN MÉDICO
      </Alert>

      <CaseHeader caseRecord={caseRecord} />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
      <Accordion defaultActiveKey={query.has("from") && query.get("from") === "control_board" ? "" : "3"} className="mt-5">
        <InssuredData
          icon="fa-search"
          eventKey="1"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={() => {}}
        />
        <HospitalData
          icon="fa-search"
          eventKey="2"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={() => {}}
        />
        <MedicAssigned
          icon="fa-search"
          eventKey="7"
          data={caseRecord}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          bindSubmitForm={() => {}}
        />
        <Documentation
          icon= "fa-search"
          icone="fa-pencil-alt"
          eventKey="4"
          data={caseRecord}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          {...props}
        />
        <MedicalFees
          icon= "fa-search"
          icone="fa-pencil-alt"
          eventKey="5"
          data={caseRecord}
          hideBtnInsurance={false}
          hideFormCreate={true}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          showForm={!query.has("from") && query.get("from") !== "control_board"}
          {...props}
        />
        <ProvidersData
            icon= "fa-pencil-alt"
            eventKey="6"
            data={caseRecord}
            disabled={false}
            disabledAll={true}
            {...props}
        />
        <AuthLetters
          icon="fa-pencil-alt"
          showForm={true}
          eventKey="3"
          data={caseRecord}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          setTotalLetters={setTotalLetters}
          {...props}
        />
      </Accordion>
      <Accordion  defaultActiveKey={query.has("from") && query.get("from") === "control_board" ? "" : "0"} key={1} className="mt-5">
        { (IfHasRole("Admin") || IfHasRole("ADMINAXA") || IfHasRole("ADMIN_REGION_CELULA") || IfHasRole("DICTAMEN-CONTLCOSTOS") ) && <MedicAssignationTrakingMedic
          icon="fa-pencil-alt"
          eventKey="0"
          data={caseRecord}
          mcMovement={letterMov}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          bindSubmitForm={bindSubmitFormMedicAssignation}
          bindErrors={bindSubmitFormMedicAssignationErrors}
        /> }
        <MedicalOpinionData
          icon="fa-pencil-alt"
          setIsFinalReject={setIsFinalReject}
          eventKey="1"
          data={caseRecord}
          letterMov={letterMov}
          totalLetters={totalLetters}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          bindSubmitForm={bindSubmitFormMedicalOpinionData}
          bindErrors={bindSubmitMedicalOpinionErrors}
        />
        <MedicalDataAdditional
          icon="fa-pencil-alt"
          eventKey="6"
          data={caseRecord}
          disabled={query.has("from") && query.get("from") === "control_board"}
          bindSubmitForm={bindSubmitFormMedicalDataAdditional}
        />
      </Accordion>
      <Accordion defaultActiveKey="5" className="mt-5">
        <Binnacle
          icon="fa-pencil-alt"
          eventKey="5"
          data={caseRecord}
          disabledAll={false}
          eventStage="Dictamen Medico"
          {...props}
        />
      </Accordion>
      </BlockCaseContext.Provider>

      <Row hidden={query.has("from") && query.get("from") === "control_board"}>
        {caseRecord.caseType !== "Revision de corte" &&
        <Col className="mt-5" hidden={isFinalReject}>
          <Button variant="warning" onClick={onClickSendMedicInformation}>
            Enviar a Informacion Médica Hospital
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="reason_sendToMedicInformation"
            id="reason_sendToMedicInformation"
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Informacion medica adicional">
              Informacion medica adicional
            </option>
            <option value="Carta parcial">Carta parcial</option>
          </Form.Control>
        </Col>}

        {caseRecord.caseType !== "Revision de corte" &&
        <Col className="mt-5 mr-5" hidden={isFinalReject}>
          <Button variant="danger" onClick={onClickSendControlDesk}>
            Enviar a Mesa de Control
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="reason_sendToControlDesk"
            id="reason_sendToControlDesk"
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Asignacion Incorrecta">Asignacion Incorrecta</option>
            <option value="Poliza VIP">Poliza VIP</option>
            <option value="Información Adicional">Información Adicional</option>
            <option value="Caso Inicial">Caso Inicial</option>
            <option value="Asignación Incorrecta VIP">Asignación Incorrecta VIP</option>
            <option value="Asignación Incorrecta VIP">Asignación Incorrecta Agil</option>
          </Form.Control>
        </Col>}

        <Col>
          <Button
            className="mt-5"
            variant="success"
            onClick={onClickCostControl}
          >
            Enviar a Control de Costos
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default MedicalOpinionDetails;
