export const STATUS = {
  CONTROL_DESK: 2,
  TRACING: 3,
  MEDIC_INFORMATION: 4,
  MEDICAL_OPINION: 5,
  COST_CONTROL: 6,
  STORE_PATIENT: 7,
  FINISHED: 8,
  RECONSIDERATION: 9,
  CANCELED: 10,
  CASES_REPOSITORY: 11,
  EXTEMPORANEOUS_DICTUM: 12,
  OUT_HOSPITAL_FOLLOW_UP: 13,
};