import React, { useState, useEffect } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import swal from 'sweetalert';
import {Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import { AdminData } from "@pages/subPages/AdminData/AdminData";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import moment from "moment";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {isCaseInCorrectModule} from "../../../_utils/checkModuleStatus";
import {STATUS} from "../../../helpers/status";

const StorePatientDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [letterMov, setLetterMov] = useState({});
  const [totalLetters, setTotalLetters] = useState("0");
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  useEffect(() => {
    subheader.setTitle("Alta Paciente");

    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      window.requires_tabulation = response.data.requiresTabulation;
      getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
        setLetterMov(currrentLetterMov.data)
      );
    });
  }, [slug]);

  // let submitFormAdminData = null;

  // const bindSubmitFormAdminData = (submitForm) =>
  //   (submitFormAdminData = submitForm);

  let history = useHistory();

  const onClickSendControlDesk = async () => {
    // submitFormAdminData();
    let reason_sendToControlDesk = document.getElementById("reason_sendToControlDesk").value;
    if (reason_sendToControlDesk === "") {
      alert("Debe seleccionar una razon");
      return;
    }
    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }
    $("#btn_save_comment").click();
    let timestamp = await axios.get('/cases/getTimestamp');
    update({ caseStatusId: 2, reasonMC: reason_sendToControlDesk, feamesaControl: moment.utc(timestamp.data).format() }, 
      caseRecord.id
      ).then((response) => {
      history.push("/alta_paciente");
    });
  };

  const onClickSendReconsiderations = async () => {
    // submitFormAdminData();
    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }
    $("#btn_save_comment").click();
    let timestamp = await axios.get('/cases/getTimestamp');
    update({
      caseStatusId: 9,
      caseType: "Reconsideración de Alta",
      feamesaControl: moment.utc(timestamp.data).format(),
      requiresTabulation: window.requires_tabulation ? window.requires_tabulation : null
    },
      caseRecord.id
      ).then((response) => {
      history.push("/alta_paciente");
    });
  };

  const onClickSendFinish = async () => {
    // submitFormAdminData();
    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }
    $("#btn_save_comment").click();
    let timestamp = await axios.get('/cases/getTimestamp');

    const dataUpdateCase =  {
      caseStatusId: 8,
      fecfinalizada: moment.utc(timestamp.data).format(),
      requiresTabulation: window.requires_tabulation ? window.requires_tabulation : null
    };

    update(dataUpdateCase, caseRecord.id).then((response) => {
      swal("Guardado Exitoso", "Caso Finalizado", "success");
      setCaseRecord({...caseRecord, ...dataUpdateCase});
    });
  }

  if (!isCaseInCorrectModule(caseRecord, [STATUS.STORE_PATIENT])) {
    return <Redirect to="/error/unauthorized"/>;
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Alta Paciente
      </Alert>

      <CaseHeader
        caseRecord={caseRecord}
        requiresTabulationSlot={
          <>
            <b>Tabulacion: </b>
            {caseRecord.requiresTabulation}
          </>
        }
      />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <InssuredData
            icon="fa-search"
            eventKey="1"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <HospitalData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <AdminData
            icon="fa-pencil-alt"
            data={caseRecord}
            eventKey="0"
            letterMov={letterMov}
            totalLetters={totalLetters}
            bindSubmitForm={() => {}}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <Documentation
            icon ="fa-search"
            eventKey="4"
            data={caseRecord}
            disabledAll={false}
            attachOptions={true}
            typesDocumentToAttach={["Estado de Cuenta Final"]}
            {...props}
          />
          <MedicalFees
            icon= "fa-pencil-search"
            eventKey="6"
            data={caseRecord}
            hideFormCreate={true}
            {...props}
          />
        </Accordion>
        <Accordion defaultActiveKey="5" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            disabledAll={false}
            eventStage="Alta Paciente"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>

      <Row hidden={caseRecord?.caseStatusId === 8}>
        <Col className="mt-5">
          <Button variant="danger" onClick={onClickSendControlDesk}>
            Enviar a Mesa de Control
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="type_hospitalization"
            id="reason_sendToControlDesk"
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Carta Erronea">Carta Erronea</option>
            <option value="Monto en Carta Insuficiente">Monto en Carta Insuficiente</option>
          </Form.Control>
        </Col>

        <Col>
          <Button className="mt-5" variant="warning" onClick={onClickSendReconsiderations}>
            Enviar a Reconsideraciones
          </Button>
        </Col>

        <Col>
          <Button className="mt-5" variant="success" onClick={onClickSendFinish}>
            Finalizar Caso
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default StorePatientDetails;
