import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import axios from "axios";
import {Prompt, Redirect, useHistory} from "react-router-dom";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { MedicAssignationTraking } from "@pages/subPages/MedicAssignationTracking/MedicAssignationTracking";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { UpdateCaseType } from "@pages/subPages/UpdateCaseType/UpdateCaseType";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { getLasMcMovementtByCase } from "@services/mcMovementsService";
import { isEmpty } from "@utils/checkObjectEmpty";
import moment from "moment";
import { RegistrationCaseType } from '@helpers/constants';
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import CaseContext from "@contexts/CaseContext";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {isCaseInCorrectModule} from "../../../_utils/checkModuleStatus";
import {STATUS} from "../../../helpers/status";

const ControlDeskDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [mcMovement, setMcMovement] = useState({});
  const [hasValidationSchema, setHasValidationSchema] = useState(true);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);
  const [shouldIgnoreLeaveMessage, setShouldIgnoreLeaveMessage] = useState(false);

  let history = useHistory();
  const inputReasonTracing = useRef();
  const inputReasonMedicInformation = useRef();

  let submitFormMedicAssignation = null;
  let submitFormMedicAssignationErrors = null;

  const bindSubmitFormMedicAssignation = (submitForm) =>
    (submitFormMedicAssignation = submitForm);

  const bindSubmitFormMedicAssignationErrors = (errors) =>
    (submitFormMedicAssignationErrors = errors);

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      updateCaseWorking(response.data.id, 1);
      getLasMcMovementtByCase(response.data.id).then((currentMcMovemet) =>
        setMcMovement(currentMcMovemet.data)
      );
    });

    subheader.setTitle("Mesa de Control");
  }, [slug]);

  window.onbeforeunload = function() {
    if (!shouldBlockCase) {
      updateCaseWorking(caseRecord.id, 0);
      return;
    }

    updateCaseLocked();
  }

  const updateCaseLocked = () => {
    if (!window.wasCommented || shouldBlockCase)
      update({ caseLocked: 1 }, caseRecord.id).then((response) => console.log("Caso bloqueado..."));
  }

  const updateCaseWorking = (caseId, caseWorkingValue) => {
    update({ caseWorking: caseWorkingValue }, caseId).then((response) => console.log("El caso se esta trabajando..."));
  }

  const getHospitalDateHire = (timestamp) => {
    if (RegistrationCaseType.includes(caseRecord.caseType) && !caseRecord.hospitalDateHire) {
      return moment.utc(timestamp.data).format();
    }
    return null;
  }

  const onClickSendDictum = async () => {
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get("/cases/getTimestamp");

      update({
        caseStatusId: 5,
        feadictamen: moment.utc(timestamp.data).format(),
        hospitalDateHire: getHospitalDateHire(timestamp.data),
        caseWorking: 0,
        caseLocked: 0,
      },caseRecord.id)
      .then((response) => {
        setShouldIgnoreLeaveMessage(true);
        history.push("/mesa_control");
      });
    }
  }

  const onClickSendTraking = async () => {
    setHasValidationSchema(false);
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      let reason_sendToSeguimiento = document.getElementById(
        "reason_sendToSeguimiento"
      ).value;
      if (reason_sendToSeguimiento === "") {
        alert("Selecciona una razon para enviar a seguimiento");
        return;
      }

      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get("/cases/getTimestamp");

      if (caseRecord.axaCategories === "" || !caseRecord.axaCategories) {
        update(
          {
            caseStatusId: 3,
            tracingReason: reason_sendToSeguimiento,
            feaseguimiento: moment.utc(timestamp.data).format(),
            caseType: "Seguimiento",
            caseWorking: 0,
            caseLocked: 0,
          },
          caseRecord.id
        ).then((response) => {
          setShouldIgnoreLeaveMessage(true);
          history.push("/mesa_control");
        });
      } else {
        update(
          {
            caseStatusId: 3,
            tracingReason: reason_sendToSeguimiento,
            feaseguimiento: moment.utc(timestamp.data).format(),
            caseWorking: 0,
            caseLocked: 0,
          },
          caseRecord.id
        ).then((response) => {
          setShouldIgnoreLeaveMessage(true);
          history.push("/mesa_control");
        });
      }
    }
  }

  const onClickSendMedicInformation = async () => {
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      let reason_sendToMedicInformation = document.getElementById(
        "reason_sendToMedicInformation"
      ).value;
      if (reason_sendToMedicInformation === "") {
        alert("Debe seleccionar una razon");
        return;
      }

      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get("/cases/getTimestamp");

      if (caseRecord.axaCategories === "" || !caseRecord.axaCategories) {
        update(
          {
            caseStatusId: 4,
            informationReason: reason_sendToMedicInformation,
            feaimedica: moment.utc(timestamp.data).format(),
            caseType: "Seguimiento",
            caseWorking: 0,
            caseLocked: 0,
          },
          caseRecord.id
        ).then((response) => {
          setShouldIgnoreLeaveMessage(true);
          history.push("/mesa_control");
        });
      } else {
        update(
          {
            caseStatusId: 4,
            informationReason: reason_sendToMedicInformation,
            feaimedica: moment.utc(timestamp.data).format(),
            caseWorking: 0,
            caseLocked: 0,
          },
          caseRecord.id
        ).then((response) => {
          setShouldIgnoreLeaveMessage(true);
          history.push("/mesa_control");
        });
      }
    }
  }

  const onClickSendHospital = async () => {
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      let reason_sendToSeguimiento = document.getElementById(
        "reason_reconsideration"
      ).value;
      if (reason_sendToSeguimiento === "") {
        alert("Selecciona una razon para enviar a hospital");
        return;
      }

      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      update(
        { caseStatusId: 7, reasonReconsideration: reason_sendToSeguimiento, caseWorking: 0, caseLocked: 0 },
        caseRecord.id
      ).then((response) => {
        setShouldIgnoreLeaveMessage(true);
        history.push("/mesa_control");
      });
    }
  }

  if (!isCaseInCorrectModule(caseRecord, [STATUS.CONTROL_DESK, STATUS.RECONSIDERATION])) {
    return <Redirect to="/error/unauthorized"/>;
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Mesa de Control
      </Alert>

      <Prompt
        message={(location, action) => {
          if (action === "PUSH" && !shouldIgnoreLeaveMessage) {
            if (!window.confirm("Desea salir de la Solicitud en Gestión?")) {
              return false;
            }
            updateCaseWorking(caseRecord.id, 0);
            //updateCaseLocked();
          }

          return true;
        }}
      />

      <CaseHeader caseRecord={caseRecord} />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <InssuredData
            icon="fa-search"
            eventKey="1"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <HospitalData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <Documentation data={caseRecord} icon="fa-search" eventKey="4" {...props} />
          <MedicalFees
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            hideBtnInsurance={false}
            hideFormCreate={true}
            showForm={true}
            {...props}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={() => {}}
            {...props}
          />
          <CaseContext.Provider value={[caseRecord, setCaseRecord]}>
            <UpdateCaseType
              icon="fa-pencil-alt"
              eventKey="6"
              data={caseRecord}
              setBlankUpdateCaseType={true}
              caseTypes={["Alta hospitalaria", "Alta express", "Aumento de suma", "Tabulador", "Seguimiento", "Dictamen", "Reconsideración de Alta", "Reconsideración de Dictamen", "Revision de corte"]}
            />
          </CaseContext.Provider>
          <MedicAssignationTraking
            icon="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            mcMovement={mcMovement}
            eventStage="Mesa de Control"
            bindSubmitForm={bindSubmitFormMedicAssignation}
            bindErrors={bindSubmitFormMedicAssignationErrors}
            hasValidationSchema={hasValidationSchema}
          />
        </Accordion>
        <Accordion defaultActiveKey="5" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            eventStage="Mesa de Control"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>

      {caseRecord.caseStatusId === 2 ? (
        <Row>
          <Col className="mt-5">
            <Button variant="warning" onClick={onClickSendTraking}>
              Enviar a Seguimiento Hospital
            </Button>

            <Form.Control
              className="mt-5 mr-3"
              as="select"
              name="reason_sendToSeguimiento"
              id="reason_sendToSeguimiento"
              ref={inputReasonTracing}
            >
              <option value="">Selecciona una opcion...</option>
              <option value="Informacion Erronea">Información Errónea</option>
              <option value="Informacion Complementaria">
                Información Complementaria
              </option>
            </Form.Control>
          </Col>

          <Col className="mt-5 mr-5">
            <Button variant="danger" onClick={onClickSendMedicInformation}>
              Enviar a Informacion Médica Hospital
            </Button>

            <Form.Control
              className="mt-5 mr-3"
              as="select"
              name="reason_sendToMedicInformation"
              id="reason_sendToMedicInformation"
              ref={inputReasonMedicInformation}
            >
              <option value="">Selecciona una opcion...</option>
              <option value="Recibido">Recibido</option>
            </Form.Control>
          </Col>

          <Col>
            <Button
              className="mt-5"
              variant="success"
              onClick={onClickSendDictum}
            >
              Enviar a Dictamen
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="mt-5">
            <Button variant="warning" onClick={onClickSendHospital}>
              Enviar a Hospital
            </Button>

            <Form.Control
              className="mt-5 mr-3"
              as="select"
              name="reason_reconsideration"
              id="reason_reconsideration"
            >
              <option value="">Selecciona una opcion...</option>
              <option value="Rechazo definitivo">Rechazo definitivo</option>
            </Form.Control>
          </Col>

          <Col>
            <Button
              className="mt-5"
              variant="success"
              onClick={onClickSendDictum}
            >
              Enviar a Dictamen
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ControlDeskDetails;
