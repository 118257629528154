
import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import {
  Alert,
  Col,
  Row,
  Card,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import {Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { CostControlData } from "@pages/subPages/CostControlData/CostControlData";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { getLasMcMovementtByCase } from "@services/mcMovementsService";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { isEmpty } from "@utils/checkObjectEmpty";
import moment from "moment";
import { ContentMedicAssignationTrakingMedic } from "@pages/subPages/MedicAssignationTracking/MedicAssignationTrackingMedic";
import { IfHasRole } from "@metronic/_helpers";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {isCaseInCorrectModule} from "../../../_utils/checkModuleStatus";
import {STATUS} from "../../../helpers/status";

const CostControlDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [mcMovement, setMcMovement] = useState({});
  const [letterMov, setLetterMov] = useState({});
  const [totalLetters, setTotalLetters] = useState(0);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      getLasMcMovementtByCase(response.data.id).then((currentMcMovemet) =>
        setMcMovement(currentMcMovemet.data)
      );
      getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
        setLetterMov(currrentLetterMov.data)
      );
    });

    subheader.setTitle("Control de Costos");
  }, []);

  let history = useHistory();

  let submitFormCostControlData = null;
  let submitFormCostControlDataErrors = null;

  const query = new URLSearchParams(props.location.search);

  const bindSubmitFormCostControlData = (submitForm) =>
    (submitFormCostControlData = submitForm);

  const bindSubmitFormCostControlDataErrors = (errors) =>
    (submitFormCostControlDataErrors = errors);

  let submitFormMedicAssignation = null;
  let submitFormMedicAssignationErrors = null;

  const bindSubmitFormMedicAssignation = (submitForm) =>
    (submitFormMedicAssignation = submitForm);

  const bindSubmitFormMedicAssignationErrors = (errors) =>
    (submitFormMedicAssignationErrors = errors);

  const onClickSendDictum = async () => {
    await submitFormCostControlData();
    if (submitFormMedicAssignation !== null) {
      await submitFormMedicAssignation();
    }

    if (isEmpty(submitFormCostControlDataErrors) && isEmpty(submitFormMedicAssignationErrors)) {
      let inputReasonDictum = document.getElementById("reason_sendToDictum").value;
      if (inputReasonDictum === "") {
        alert("Debe seleccionar una razon");
        return;
      }
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get('/cases/getTimestamp');
      update({ caseStatusId: 5, dictumReason: inputReasonDictum, feadictamen: moment.utc(timestamp.data).format()}, 
        caseRecord.id
        ).then((response) => {
        history.push("/control_costos");
      });
    }
  }

  const onClickSendControlDesk = async () => {
    await submitFormCostControlData();
    if (submitFormMedicAssignation !== null) {
      await submitFormMedicAssignation();
    }

    if (isEmpty(submitFormCostControlDataErrors) && isEmpty(submitFormMedicAssignationErrors)) {
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      update({ caseStatusId: 2},
          caseRecord.id
      ).then((response) => {
        history.push("/control_costos");
      });
    }
  }

  const onClickPatientStore = async () => {
    await submitFormCostControlData();

    if (submitFormMedicAssignation !== null) {
      await submitFormMedicAssignation();
    }

    if (isEmpty(submitFormCostControlDataErrors) && isEmpty(submitFormMedicAssignationErrors)) {
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      let timestamp = await axios.get('/cases/getTimestamp');
      update(
        { caseStatusId: 7, feaapaciente: moment.utc(timestamp.data).format()}, 
        caseRecord.id
        ).then((response) => {
        history.push("/control_costos");
      });
    }
  }

  const onClickSendMedicInformation = async () => {
    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }
    $("#btn_save_comment").click();
    let timestamp = await axios.get('/cases/getTimestamp');
    update({ caseStatusId: 4, feaimedica: moment.utc(timestamp.data).format() }, caseRecord.id)
      .then((response) => {
        history.push("/dictamen_medico");
      });
  }

  if (!isCaseInCorrectModule(caseRecord, [STATUS.COST_CONTROL])) {
    return <Redirect to="/error/unauthorized"/>;
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Control de Costos
      </Alert>

      <CaseHeader caseRecord={caseRecord} />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
      <Accordion defaultActiveKey={query.has("from") && query.get("from") === "control_board" ? "" : "0"} className="mt-5">
        <InssuredData
          icon="fa-search"
          eventKey="1"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={()=>{}}
        />
        <HospitalData
          icon="fa-search"
          eventKey="2"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={()=>{}}
        />
        { (IfHasRole("Admin") || IfHasRole("ADMINAXA") || IfHasRole("ADMIN_REGION_CELULA") || IfHasRole("DICTAMEN-CONTLCOSTOS") ) && <ContentMedicAssignationTrakingMedic
          icon="fa-pencil-alt"
          eventKey="0"
          data={caseRecord}
          mcMovement={letterMov}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          bindSubmitForm={bindSubmitFormMedicAssignation}
          bindErrors={bindSubmitFormMedicAssignationErrors}
        /> }
        <CostControlData
          icon="fa-pencil-alt"
          eventKey="0"
          data={caseRecord}
          mcMovement={mcMovement}
          letterMov={letterMov}
          totalLetters={totalLetters}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          showForm={true}
          bindSubmitForm={bindSubmitFormCostControlData}
          bindErrors={bindSubmitFormCostControlDataErrors}
          totalDeviations={caseRecord.hospital?.atc?.toLowerCase() === 'no'}
        />
        <AuthLetters
          icon="fa-search"
          showForm={false}
          hideDeleteOption={true}
          eventKey="3"
          data={caseRecord}
          disabledAll={false}
          setTotalLetters={setTotalLetters}
          {...props}
        />
        <Documentation
          icon ="fa-search"
          eventKey="4"
          data={caseRecord}
          disabledAll={query.has("from") && query.get("from") === "control_board"}
          typesDocument={[
            "Aviso de Accidente", "Carta de Autorización", "Comprobante de seguro o poliza", "Denuncia ante MP",
            "Estudios de Gabinete", "Estudios de Laboratorio", "Estudios que Confirmen DX", "Historia Clinica",
            "Honorarios Médicos", "Identificacion", "Informe Medico Actualizado", "Nota de Evolución",
            "Nota Postquirurgica", "Resumen Medico Actualizado", "Visado Final", "Otro",
          ]}
          {...props}
        />
        <MedicalFees
          icon= "fa-search"
          icone="fa-pencil-alt"
          eventKey="5"
          data={caseRecord}
          hideBtnInsurance={false}
          hideFormCreate={true}
          showForm={!query.has("from") && query.get("from") !== "control_board"}
          {...props}
        />
      </Accordion>
      <Accordion defaultActiveKey="5" className="mt-5">
        <Binnacle
          icon="fa-pencil-alt"
          eventKey="5"
          data={caseRecord}
          disabledAll={false}
          eventStage="Control de Costos"
          {...props}
        />
      </Accordion>
      </BlockCaseContext.Provider>

      <Row hidden={query.has("from") && query.get("from") === "control_board"}>
        {caseRecord.caseType === "Revision de corte" &&
        <Col className="mt-5">
          <Button variant="warning" onClick={onClickSendMedicInformation}>
            Enviar a Informacion Médica Hospital
          </Button>
        </Col>}

        <Col className="mt-5">
          <Button variant="danger" onClick={onClickSendDictum}>
            Enviar a Dictamen
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="reason_sendToDictum"
            id="reason_sendToDictum"
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Carta Erronea">Carta Erronea</option>
            <option value="Monto en Carta Insuficiente">Monto en Carta Insuficiente</option>
          </Form.Control>
        </Col>

        <Col className="mt-5">
          <Button variant="danger" onClick={onClickSendControlDesk}>
            Enviar a Mesa de Control
          </Button>
        </Col>

        {caseRecord.caseType !== "Revision de corte" &&
        <Col>
          <Button
            className="mt-5"
            variant="success"
            onClick={onClickPatientStore}
          >
            Enviar a Alta Paciente
          </Button>
        </Col>}
      </Row>
    </>
  );
};

export default CostControlDetails;
